<template>
  <div class="mapHeader">
    <van-search
        v-model="query"
        show-action
        placeholder="请输入搜索关键词"
        @search="onSearch"
        @cancel="onCancel"
    />
  </div>
</template>

<script>
export default {
  name: 'mapHeader',
  data() {
    return {
      query: ''
    }
  },
  methods: {
    onSearch: function() {
      this.$emit('search', { query: this.query })
    },
    onCancel: function() {
      this.query = ''
      this.onSearch()
    }
  }
}
</script>

<style lang="scss" scoped>

.mapHeader {
  width: 100%;
  height: 55px;
}

</style>