<template>
  <div class="mapItem" @click="pushMapDetail">
    <div class="imageView">
      <img :src="$getDataValue(dataInfo, 'photoUrl', '')">
    </div>
    <div class="content">
      <div class="textView">{{ $getDataValue(dataInfo, 'name', '') }}</div>
      <div class="textView">{{ $getDataValue(dataInfo, 'tenantName', '') }}</div>
      <div class="textView">{{ '更新时间:' + getFullTimeMsg($getDataValue(dataInfo, 'updateTime', '')) }}</div>
    </div>
    <div style="clear: both"></div>
  </div>
</template>

<script>
export default {
  name: 'mapItem',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    pushMapDetail:function() {
      this.$emit('pushMapDetail', this.dataInfo)
    },
    getFullTimeMsg: function (time) {
      const date = new Date(time)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}年${month <= 9 ? '0' + month : month}月${day <= 9 ? '0' + day : day}日`
    },
  }
}
</script>

<style lang="scss" scoped>

.mapItem {
  width: 100%;
  margin: 10px 0px 0px 0px;
  background-color: white;

  .imageView {
    float: left;
    width: 100px;
    height: 100px;
    margin: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    float: right;
    width: calc(100% - 130px);
    height: 100px;
    margin-top: 5px;
    margin-right: 10px;

    .textView {
      width: 100%;
      height: 30px;
      line-height: 30px;
    }

  }

}

</style>