<template>
  <h-stack class="paginationVue">
    <el-pagination
        @current-change="currentChange"
        :page-size="pageSize"
        :pager-count="5"
        :current-page="page"
        layout="prev, pager, next"
        :total="totalCount"
    >
    </el-pagination>
  </h-stack>
</template>

<script>
import HStack from '@/components/hStack/hStack'

export default {
  name: 'paginationVue',
  components: { HStack },
  props: {
    page: {
      default: 0
    },
    pageSize: {
      default: 50
    },
    totalCount: {
      default: 0
    }
  },
  data() {
    return {}
  },
  methods: {
    currentChange: function(index) {
      this.$emit('currentChange', index)
    }
  }
}
</script>

<style lang="scss" scoped>

.paginationVue {
  height: 40px;
  background-color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

</style>
