<template>
  <div class="mapRenderList" id="mapRenderList">
    <map-header @search="startSearch"></map-header>
    <div class="mapContent">
      <map-item @pushMapDetail="pushMapDetail" v-for="(item, index) in mapList" :data-info="item">

      </map-item>
    </div>
    <pagination-vue @current-change="currentChange"
                    :page-size="pageSize"
                    :pager-count="5"
                    :total-count="totalCount"
                    :current-page="page"
    ></pagination-vue>


  </div>
</template>

<script>
import MapItem from '@views/mapRender/mapItem/mapItem'
import MapHeader from '@views/mapRender/mapHeader/mapHeader'
import PaginationVue from '@components/pagination/paginationVue'
import { Loading } from 'element-ui'

export default {
  name: 'mapRenderList',
  components: { PaginationVue, MapHeader, MapItem },
  data() {
    return {
      query: '',
      page: 1,
      pageSize: 50,
      totalCount: 0,
      mapList: [],
      loading: false,
      loadingView: null
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getMapList()
  },
  methods: {
    startLoading: function() {
      let dom = document.getElementById('mapRenderList')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    startSearch: function(res) {
      this.query = res.query
      this.currentChange(1)
    },
    currentChange: function(index) {
      this.page = index
      this.getMapList()
    },
    getMapList: function() {
      this.loading = true
      this.$api.mapApi.getMapList({
        deleted: 0,
        query: this.query,
        page: this.page,
        pageSize: this.pageSize,
        tenantId: ''
      }).then(res => {
        this.loading = false
        this.mapList = res.data
        this.totalCount = res.totalCount
      }).catch(e => {
        this.loading = false
      })
    },
    pushMapDetail: function(item) {
      if (typeof item['id'] == 'undefined') {
        return
      }
      let id = item['id']
      this.$push('mapRenderView', { mapId: id })
    }
  }

}
</script>

<style lang="scss" scoped>

.mapRenderList {
  width: 100%;
  height: 100%;

  .mapContent {
    width: 100%;
    height: calc(100% - 95px);
    overflow: scroll;
  }


}

</style>